.hero {
  width: calc(100% - 30px);
  height: 100%;
  /* issue with css loader; can't import from public... */
  background-image: url("hero.jpg");
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
}

.hero-container {
  width: 100%;
  display: flex;
  height: max(50vh, 500px);
  padding-top: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.hero-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(33, 35, 37, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hero-gradient h1 {
  color: var(--gegf-bg);
  font-weight: 700;
  text-align: center;
  font-family: "Caveat";
  font-size: 4rem;
  user-select: none;
  cursor: default;
  letter-spacing: 5px;
  line-height: 2.75rem;
}

.hero-gradient button {
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  font-size: 1.5rem;
  letter-spacing: 1px;
  border: none;
  border: 2px solid var(--gegf-bg);
  border-radius: 20px;
  padding: 5px 40px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.hero-gradient button:hover {
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  transform: scale(1.1);
}

.hero-gradient button:active {
  background-color: var(--gegf-bg-dark);
  border: 2px solid var(--gegf-bg-dark);
}

.contact-cta-container {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-cta-container div {
  width: 400px;
}
