.videos-list-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: min(600px, 90%);
  justify-content: space-around;
}

.video-card {
  overflow: hidden;
  background-color: var(--gegf-bg-dark);
  width: 45%;
  margin: 10px 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.video-card:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
}

.video-card:first-child {
  width: 95%;
}

.video-card:first-child .video-card-image {
  height: 40vh;
}

.video-card:first-child .video-info p {
  font-size: 1rem;
}

.video-card:first-child .video-info p:last-child {
  font-size: 0.75rem;
}

.video-card h2 {
  margin: 0;
  font-size: 1rem;
}

.video-info {
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.video-info p {
  font-size: 0.75rem;
  margin: 0;
  text-overflow: ellipsis;
}

.video-info p:last-child {
  font-weight: 300;
  font-size: 0.5rem;
}

.video-card-image {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  height: 20vh;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .video-card-image {
    height: 15vh;
  }
}

.open-video-container {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  flex-direction: column;
  perspective: 0;
}

.open-video {
  background-color: var(--gegf-bg);
  border-radius: 8px;
  width: 80vw;
  height: min(45vw, 90%);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9 !important;
  overflow: hidden;
  padding: 8px;
  perspective: 0;
  z-index: 9999;
  margin: 0;
}

.open-video-video {
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 100%;
}

.video-extern-button {
  margin-top: 10px;
  background-color: var(--gegf-bg);
  border: none;
  border-radius: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  transform: 0.3s ease-in-out;
  cursor: pointer;
}

.video-extern-button:hover {
  background-color: var(--gegf-bg-dark);
}

.video-extern-button img:first-child {
  padding-right: 20px;
}
