@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
  --gegf-bg: #d7e4d8;
  --gegf-bg-dark: #c4d2c6;
  /* --gegf-text: #4e6b2c; -- not accessible enough... */
  --gegf-text: #2d401c;
  --navbar-top: calc(env(safe-area-inset-top) + 25px);
}

html,
body {
  font-family: "Poppins", sans-serif;
  background-color: #d7e4d8 !important;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
