.safe-area-content {
  padding-top: 75px;
}

.temp-research-tag {
  text-align: center;
}

.header {
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 4rem;
  color: var(--gegf-text);
  text-align: center;
  user-select: none;
}

.paragraphs {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.paragraphs .paragraph {
  margin: 10px 10px;
  max-width: 600px;
  min-width: 300px;
  text-align: justify;
}

#content-container {
  padding-top: env(safe-area-inset-top);
  /* padding bottom messes with safari mobile ???? */
}
