.navbar {
  position: fixed;
  top: 25px;
  left: 25px;
  right: 15px;
  height: 55px;
  display: flex;
  z-index: 20;
}

.nav-button {
  position: fixed;
  top: var(--navbar-top);
  border-radius: 16px;
  border: none;
  background-color: var(--gegf-bg);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  height: 55px;
  cursor: pointer;
}

.nav-button * {
  transition: 0.3s ease-in-out;
}

.nav-button:hover * {
  transform: scale(1.1);
}

.nav-button:active * {
  transform: scale(0.9);
}

.scrollup-button {
  position: fixed;
  left: 25px;
  width: 55px;
  z-index: 21;
}

.no-shadow {
  box-shadow: none;
}

.logo-pill {
  left: 25px;
  height: 55px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 22;
}

.logo-pill img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-button {
  width: 75px;
  margin-left: auto;
  right: 25px;
  z-index: 10;
}

.menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 23;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu {
  /* background-color: var(--gegf-bg); */
  background: none;
  border-radius: 16px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 40;
  cursor: default;
  width: min(600px, 75%);
  background-color: var(--gegf-bg);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  max-height: 80%;
  overflow: auto;
}

.menu-close {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--gegf-bg);
  opacity: 0;
  margin-top: 30px;
}

@media screen and (min-width: 600px) {
  .menu-container {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .menu {
    width: 300px;
    margin-top: 25px;
    margin-right: 25px;
  }
}

.menu-close img {
  width: 30px;
  height: 30px;
}

.icon-link {
  align-items: center;
  background-color: var(--gegf-bg);
  display: flex;
  flex-direction: row;
  margin: 0;
  width: min(500px, 95%);
  max-width: 500px;
  border: none;
  border-radius: 100px;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: var(--gegf-bg-dark);
  height: 60px;
  padding-right: 15px;
  overflow: hidden;
}

.icon-link * {
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.icon-link img {
  display: inline;
  top: 0.125rem;
  width: 24px;
}

.icon-link-icon-container {
  height: 100%;
  width: 60px;
  backdrop-filter: brightness(0.9);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-link:hover {
  filter: brightness(1.05);
}

.icon-link p {
  margin-left: auto;
  font-size: 1.5rem;
  height: 30px;
  text-decoration: none !important;
  color: var(--gegf-text);
  margin-right: 15px;
}

.icon-link:hover * img {
  transform: scale(1.25);
}

.icon-link:active * img {
  transform: scale(0.9);
}
