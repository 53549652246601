@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
:root {
  --gegf-bg: #d7e4d8;
  --gegf-bg-dark: #c4d2c6;
  /* --gegf-text: #4e6b2c; -- not accessible enough... */
  --gegf-text: #2d401c;
  --navbar-top: calc(env(safe-area-inset-top) + 25px);
}

html,
body {
  font-family: "Poppins", sans-serif;
  background-color: #d7e4d8 !important;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.safe-area-content {
  padding-top: 75px;
}

.temp-research-tag {
  text-align: center;
}

.header {
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 4rem;
  color: var(--gegf-text);
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.paragraphs {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.paragraphs .paragraph {
  margin: 10px 10px;
  max-width: 600px;
  min-width: 300px;
  text-align: justify;
}

#content-container {
  padding-top: env(safe-area-inset-top);
  /* padding bottom messes with safari mobile ???? */
}

.navbar {
  position: fixed;
  top: 25px;
  left: 25px;
  right: 15px;
  height: 55px;
  display: flex;
  z-index: 20;
}

.nav-button {
  position: fixed;
  top: var(--navbar-top);
  border-radius: 16px;
  border: none;
  background-color: var(--gegf-bg);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  height: 55px;
  cursor: pointer;
}

.nav-button * {
  transition: 0.3s ease-in-out;
}

.nav-button:hover * {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.nav-button:active * {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.scrollup-button {
  position: fixed;
  left: 25px;
  width: 55px;
  z-index: 21;
}

.no-shadow {
  box-shadow: none;
}

.logo-pill {
  left: 25px;
  height: 55px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 22;
}

.logo-pill img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-button {
  width: 75px;
  margin-left: auto;
  right: 25px;
  z-index: 10;
}

.menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 23;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu {
  /* background-color: var(--gegf-bg); */
  background: none;
  border-radius: 16px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 40;
  cursor: default;
  width: min(600px, 75%);
  background-color: var(--gegf-bg);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  max-height: 80%;
  overflow: auto;
}

.menu-close {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--gegf-bg);
  opacity: 0;
  margin-top: 30px;
}

@media screen and (min-width: 600px) {
  .menu-container {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .menu {
    width: 300px;
    margin-top: 25px;
    margin-right: 25px;
  }
}

.menu-close img {
  width: 30px;
  height: 30px;
}

.icon-link {
  align-items: center;
  background-color: var(--gegf-bg);
  display: flex;
  flex-direction: row;
  margin: 0;
  width: min(500px, 95%);
  max-width: 500px;
  border: none;
  border-radius: 100px;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: var(--gegf-bg-dark);
  height: 60px;
  padding-right: 15px;
  overflow: hidden;
}

.icon-link * {
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.icon-link img {
  display: inline;
  top: 0.125rem;
  width: 24px;
}

.icon-link-icon-container {
  height: 100%;
  width: 60px;
  -webkit-backdrop-filter: brightness(0.9);
          backdrop-filter: brightness(0.9);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-link:hover {
  -webkit-filter: brightness(1.05);
          filter: brightness(1.05);
}

.icon-link p {
  margin-left: auto;
  font-size: 1.5rem;
  height: 30px;
  text-decoration: none !important;
  color: var(--gegf-text);
  margin-right: 15px;
}

.icon-link:hover * img {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.icon-link:active * img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.videos-list-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: min(600px, 90%);
  justify-content: space-around;
}

.video-card {
  overflow: hidden;
  background-color: var(--gegf-bg-dark);
  width: 45%;
  margin: 10px 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.video-card:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
}

.video-card:first-child {
  width: 95%;
}

.video-card:first-child .video-card-image {
  height: 40vh;
}

.video-card:first-child .video-info p {
  font-size: 1rem;
}

.video-card:first-child .video-info p:last-child {
  font-size: 0.75rem;
}

.video-card h2 {
  margin: 0;
  font-size: 1rem;
}

.video-info {
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.video-info p {
  font-size: 0.75rem;
  margin: 0;
  text-overflow: ellipsis;
}

.video-info p:last-child {
  font-weight: 300;
  font-size: 0.5rem;
}

.video-card-image {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  height: 20vh;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .video-card-image {
    height: 15vh;
  }
}

.open-video-container {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  flex-direction: column;
  -webkit-perspective: 0;
          perspective: 0;
}

.open-video {
  background-color: var(--gegf-bg);
  border-radius: 8px;
  width: 80vw;
  height: min(45vw, 90%);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9 !important;
  overflow: hidden;
  padding: 8px;
  -webkit-perspective: 0;
          perspective: 0;
  z-index: 9999;
  margin: 0;
}

.open-video-video {
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 100%;
}

.video-extern-button {
  margin-top: 10px;
  background-color: var(--gegf-bg);
  border: none;
  border-radius: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  -webkit-transform: 0.3s ease-in-out;
          transform: 0.3s ease-in-out;
  cursor: pointer;
}

.video-extern-button:hover {
  background-color: var(--gegf-bg-dark);
}

.video-extern-button img:first-child {
  padding-right: 20px;
}

.contact-card-container {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30000000000;
}

.contact-card {
  max-width: 80vw;
  max-height: 70vh;
  background-color: var(--gegf-bg);
  border-radius: 8px;
  padding: 15px;
  flex-direction: column;
  overflow: auto;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
}

.contact-people {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.contact-header {
  background-color: red;
}

.contact-person {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  max-width: 50vw;
  flex: 1 1;
}

.contact-card h2 {
  text-align: center;
  padding: 0px 40px;
}

.contact-person img {
  border-radius: 100%;
  width: 78px;
  height: 78px;
  box-shadow: 0px 1px 4px 4px var(--gegf-bg-dark);
  object-fit: cover;
}

.contact-entry {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--gegf-bg-dark);
  display: flex;
  padding: 0;
  border-radius: 100px;
  margin-bottom: 10px;
  cursor: pointer;
}

.contact-entry:hover {
  background-color: var(--gegf-bg);
}

.contact-entry p {
  padding-left: 20px;
  padding-right: 20px;
}

.contact-entry img {
  padding-left: 20px;
  background-color: var(--gegf-bg-dark) !important;
  border: none !important;
  border-radius: 100% !important;
}

.contact-entry:hover img {
  padding-left: 20px;
  background-color: var(--gegf-bg) !important;
  border: none;
}

.footer-card {
  background-color: var(--gegf-bg-dark);
  border-radius: 16px;
  margin: 15px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px;
  margin-bottom: 30px;
}

.footer-card img {
  max-width: 300px;
}

.links {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.links * {
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.links *:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.primary-contact {
  margin-top: 20px;
  text-align: center;
}

.footer-hr {
  width: 30%;
  border: 2px solid var(--gegf-bg-dark);
  height: 1px;
}

.contact-button {
  text-decoration: underline;
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 800;
  color: var(--gegf-text);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.contact-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.adv-header-pill {
  border-radius: 16px;
  z-index: 30;
  height: 55px;
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  font-weight: 700;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 10px;
}

.adv-header-container {
  position: fixed;
  z-index: 5;
  top: 25px;
  height: 55px;
  left: 0;
  right: 0;
  display: flex;
}

.adv-header-pill * {
  text-align: center;
}

.adv-header-big {
  width: -webkit-min-content;
  width: min-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 3px;
  font-size: 3rem;
  color: var(--gegf-text);
}

.adv-header-big * {
  text-align: center;
}

.adv-header {
  padding: 10px;
  font-size: 2rem;
  text-align: center;
}

.hero {
  width: calc(100% - 30px);
  height: 100%;
  /* issue with css loader; can't import from public... */
  background-image: url(/static/media/hero.9e0513d4.jpg);
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
}

.hero-container {
  width: 100%;
  display: flex;
  height: max(50vh, 500px);
  padding-top: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.hero-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(33, 35, 37, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hero-gradient h1 {
  color: var(--gegf-bg);
  font-weight: 700;
  text-align: center;
  font-family: "Caveat";
  font-size: 4rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
  letter-spacing: 5px;
  line-height: 2.75rem;
}

.hero-gradient button {
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  font-size: 1.5rem;
  letter-spacing: 1px;
  border: none;
  border: 2px solid var(--gegf-bg);
  border-radius: 20px;
  padding: 5px 40px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.hero-gradient button:hover {
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.hero-gradient button:active {
  background-color: var(--gegf-bg-dark);
  border: 2px solid var(--gegf-bg-dark);
}

.contact-cta-container {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-cta-container div {
  width: 400px;
}

