.contact-card-container {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30000000000;
}

.contact-card {
  max-width: 80vw;
  max-height: 70vh;
  background-color: var(--gegf-bg);
  border-radius: 8px;
  padding: 15px;
  flex-direction: column;
  overflow: auto;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
}

.contact-people {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.contact-header {
  background-color: red;
}

.contact-person {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  max-width: 50vw;
  flex: 1;
}

.contact-card h2 {
  text-align: center;
  padding: 0px 40px;
}

.contact-person img {
  border-radius: 100%;
  width: 78px;
  height: 78px;
  box-shadow: 0px 1px 4px 4px var(--gegf-bg-dark);
  object-fit: cover;
}

.contact-entry {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--gegf-bg-dark);
  display: flex;
  padding: 0;
  border-radius: 100px;
  margin-bottom: 10px;
  cursor: pointer;
}

.contact-entry:hover {
  background-color: var(--gegf-bg);
}

.contact-entry p {
  padding-left: 20px;
  padding-right: 20px;
}

.contact-entry img {
  padding-left: 20px;
  background-color: var(--gegf-bg-dark) !important;
  border: none !important;
  border-radius: 100% !important;
}

.contact-entry:hover img {
  padding-left: 20px;
  background-color: var(--gegf-bg) !important;
  border: none;
}
