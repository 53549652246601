.footer-card {
  background-color: var(--gegf-bg-dark);
  border-radius: 16px;
  margin: 15px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px;
  margin-bottom: 30px;
}

.footer-card img {
  max-width: 300px;
}

.links {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.links * {
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.links *:hover {
  transform: scale(1.1);
}

.primary-contact {
  margin-top: 20px;
  text-align: center;
}

.footer-hr {
  width: 30%;
  border: 2px solid var(--gegf-bg-dark);
  height: 1px;
}

.contact-button {
  text-decoration: underline;
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 800;
  color: var(--gegf-text);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.contact-button:hover {
  transform: scale(1.1);
}
