.adv-header-pill {
  border-radius: 16px;
  z-index: 30;
  height: 55px;
  background-color: var(--gegf-bg);
  color: var(--gegf-text);
  font-weight: 700;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 10px;
}

.adv-header-container {
  position: fixed;
  z-index: 5;
  top: 25px;
  height: 55px;
  left: 0;
  right: 0;
  display: flex;
}

.adv-header-pill * {
  text-align: center;
}

.adv-header-big {
  width: min-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 3px;
  font-size: 3rem;
  color: var(--gegf-text);
}

.adv-header-big * {
  text-align: center;
}

.adv-header {
  padding: 10px;
  font-size: 2rem;
  text-align: center;
}
